<template>
  <v-navigation-drawer
    v-model="helpDrawer"
    app
    right
    class="pa-2"
    disable-resize-watcher
  >
    <div class="d-flex justify-space-between mb-4">
      <h1>{{ $t('components.help-drawer.tutorials') }}</h1>
      <v-icon @click="SET_HELP_DRAWER(false)">fa fa-times</v-icon>
    </div>
    <ul class="no-styles">
      <li
        v-for="(tutorial, i) in tutorialsArray"
        :key="tutorial.id"
        class="tutorial"
      >
        <div
          class="tutorial__title d-flex justify-space-between font-weight-bold clickable"
          @click="selectedTutorial = selectedTutorial === i ? null : i"
        >
          <span>{{ i + 1 }}. {{ tutorial.title }}</span>
          <v-icon small
            >fa fa-angle-{{ selectedTutorial === i ? 'up' : 'down' }}</v-icon
          >
        </div>
        <v-card
          v-show="i === selectedTutorial"
          flat
          outlined
          class="tutorial__info grey darken-3 pa-2 mb-4"
        >
          <div class="text-caption white--text">
            {{ tutorial.text }}
          </div>

          <div v-if="tutorial.video" class="mt-2">
            <LightboxVideo
              :src="tutorial.video"
              :poster="require('@/assets/img/dashboard_small.png')"
            />
            <div class="text-caption text-center white--text">
              {{ $t('components.help-drawer.click-above-video') }}
            </div>
          </div>
        </v-card>
      </li>
    </ul>
  </v-navigation-drawer>
</template>

<script>
import { mapMutations } from 'vuex'
import tutorials from '@/content/tutorials'

import LightboxVideo from '@/components/LightboxVideo'

export default {
  components: {
    LightboxVideo,
  },

  data() {
    return {
      selectedTutorial: null,
    }
  },

  computed: {
    helpDrawer: {
      get: function() {
        return this.$store.state.app.helpDrawer
      },
      set: function(newValue) {
        return this.SET_HELP_DRAWER(newValue)
      },
    },

    tutorials() {
      return tutorials(this.$i18n)
    },

    tutorialsArray() {
      const tutorialsMap = this.tutorials
      return Object.keys(tutorialsMap).map(k => {
        return {
          id: k,
          ...tutorialsMap[k],
        }
      })
    },
  },

  methods: {
    ...mapMutations('app', ['SET_HELP_DRAWER']),
  },
}
</script>

<style lang="scss" scoped>
.tutorial {
  &__title {
    user-select: none;
  }
  &__info {
    white-space: pre-line;
  }
}
</style>
