<template>
  <v-dialog v-model="dialog" width="auto ">
    <template v-slot:activator="{ on }">
      <video
        v-on="on"
        :src="src"
        class="d-block clickable"
        width="100%"
        :poster="poster"
      >
        <i18n path="app.video-fallback">
          <a :href="src">{{ $t('app.download-the-video') }}</a>
        </i18n>
      </video>
    </template>

    <v-sheet v-if="dialog">
      <video
        width="100%"
        :src="src"
        controls
        preload
        autoplay
        loop
        muted
        class="lightbox-video__lightbox-vid"
        :poster="poster"
      >
        <i18n path="app.video-fallback">
          <a :href="src">{{ $t('app.download-the-video') }}</a>
        </i18n>
      </video>
    </v-sheet>
  </v-dialog>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    poster: {
      type: String,
    },
  },

  data() {
    return {
      dialog: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.lightbox-video {
  &__lightbox-vid {
    display: block;
    max-width: 1000px;
  }
}
</style>
