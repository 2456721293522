export default function tutorials(i18n) {
  return {
    createTask: {
      title: i18n.t('other.tutorials.create-a-task'),
      text: i18n.t('other.tutorials.create-a-task-text'),
      video: '/mp4/tutorials/createTask.mp4',
    },
    completeTask: {
      title: i18n.t('other.tutorials.complete-a-task'),
      text: i18n.t('other.tutorials.complete-a-task-text'),
      video: '/mp4/tutorials/completeTask.mp4',
    },
    deleteEntry: {
      title: i18n.t('other.tutorials.delete-an-entry'),
      text: i18n.t('other.tutorials.delete-an-entry-text'),
      video: '/mp4/tutorials/deleteEntry.mp4',
    },
    createNote: {
      title: i18n.t('other.tutorials.create-a-note'),
      text: i18n.t('other.tutorials.create-a-note-text'),
      video: '/mp4/tutorials/createNote.mp4',
    },
    createEvent: {
      title: i18n.t('other.tutorials.create-an-event'),
      text: i18n.t('other.tutorials.create-an-event-text'),
      video: '/mp4/tutorials/createEvent.mp4',
    },
    createWeekEntry: {
      title: i18n.t('other.tutorials.create-week-entry'),
      text: i18n.t('other.tutorials.create-week-entry-text'),
      video: '/mp4/tutorials/createWeekEntry.mp4',
    },
    createMonthEntry: {
      title: i18n.t('other.tutorials.create-month-entry'),
      text: i18n.t('other.tutorials.create-month-entry-text'),
      video: '/mp4/tutorials/createMonthEntry.mp4',
    },

    changeDay: {
      title: i18n.t('other.tutorials.change-day'),
      text: i18n.t('other.tutorials.change-day-text'),
      video: '/mp4/tutorials/changeDay.mp4',
    },
    reorderEntries: {
      title: i18n.t('other.tutorials.reorder-entries'),
      text: i18n.t('other.tutorials.reorder-entries-text'),
      video: '/mp4/tutorials/reorderEntries.mp4',
    },
    migrateDate: {
      title: i18n.t('other.tutorials.migrate-date'),
      text: i18n.t('other.tutorials.migrate-date-text'),
      video: '/mp4/tutorials/migrateDate.mp4',
    },
    createList: {
      title: i18n.t('other.tutorials.create-list'),
      text: i18n.t('other.tutorials.create-list-text'),
      video: '/mp4/tutorials/createList.mp4',
    },
    addPrompt: {
      title: i18n.t('other.tutorials.add-prompt'),
      text: i18n.t('other.tutorials.add-prompt-text'),
      video: '/mp4/tutorials/addPrompt.mp4',
    },
  }
}
